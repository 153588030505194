var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row mx-10"},[_c('div',{staticClass:"d-flex flex-column"},[_c('h3',{staticClass:"font-weight-bold blue-grey--text font text-uppercase font-weight-medium font-md"},[_vm._v(" All Schools ")]),_c('p',{staticClass:"font-weight-medium grey--text text--darken-1 font-md font"},[_vm._v(" List of all schools below ")])])])]),_c('v-col',{staticClass:"mt-n4",attrs:{"cols":"12","sm":"12","lg":"12","md":"12","xl":"12"}},[_c('v-data-table',{staticClass:"font box-shadow-light font-weight-medium text-capitalize mx-10",attrs:{"headers":_vm.headers,"items":_vm.allSchools,"loading":_vm.pageLoading,"server-items-length":_vm.paginate.total,"options":_vm.options,"loading-text":"Loading... Please wait","footer-props":{
          itemsPerPageOptions: [30, 40, 50]
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":{
                  name: 'admin.schools.students',
                  params: {
                    id: item._id
                  },
                  query: {
                    name: item.name
                  }
                },"icon":"","fab":"","small":""}},on),[_c('i',{staticClass:"material-icons-outlined font-md"},[_vm._v("visibility")])])]}}],null,true)},[_c('span',{staticClass:" font font-sm"},[_vm._v("View Students")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":{
                  name: 'admin.schools.transactions',
                  params: {
                    id: item._id
                  },
                  query: {
                    name: item.name
                  }
                },"icon":"","fab":"","small":""}},on),[_c('i',{staticClass:"material-icons-outlined font-md"},[_vm._v("money")])])]}}],null,true)},[_c('span',{staticClass:" font font-sm"},[_vm._v("View All Transactions")])])]}}])})],1)],1),_c('SnackBar',{attrs:{"showSnackBar":_vm.showSnackBar,"message":_vm.message,"status":_vm.status}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }