<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex flex-row mx-10">
          <div class="d-flex flex-column">
            <h3
              class="font-weight-bold blue-grey--text font text-uppercase font-weight-medium font-md"
            >
              All Schools
            </h3>
            <p
              class="font-weight-medium grey--text text--darken-1 font-md font"
            >
              List of all schools below
            </p>
          </div>
        </div>
      </v-col>

      <v-col cols="12" sm="12" lg="12" md="12" xl="12" class="mt-n4">
        <v-data-table
          :headers="headers"
          :items="allSchools"
          :loading="pageLoading"
          :server-items-length="paginate.total"
          :options.sync="options"
          class="font box-shadow-light font-weight-medium text-capitalize mx-10"
          loading-text="Loading... Please wait"
          :footer-props="{
            itemsPerPageOptions: [30, 40, 50]
          }"
        >
          <template #item.actions="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  :to="{
                    name: 'admin.schools.students',
                    params: {
                      id: item._id
                    },
                    query: {
                      name: item.name
                    }
                  }"
                  v-on="on"
                  icon
                  fab
                  small
                >
                  <i class="material-icons-outlined font-md">visibility</i>
                </v-btn>
              </template>
              <span class=" font font-sm">View Students</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  :to="{
                    name: 'admin.schools.transactions',
                    params: {
                      id: item._id
                    },
                    query: {
                      name: item.name
                    }
                  }"
                  v-on="on"
                  icon
                  fab
                  small
                >
                  <i class="material-icons-outlined font-md">money</i>
                </v-btn>
              </template>
              <span class=" font font-sm">View All Transactions</span>
            </v-tooltip>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :loading="isLoading"
                  @click="$store.dispatch('members/delete', item._id)"
                  icon
                  fab
                  small
                >
                  <i class="material-icons-outlined font-md">delete</i>
                </v-btn>
              </template>
              <span class=" font font-sm">Delete Member Details</span>
            </v-tooltip> -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>

<script>
import { debounce } from "lodash";
import { loadComponent } from "@/utils/helpers";
import { mapGetters } from "vuex";
import SnackBarMixins from "../../../../mixins/SnackBarMIxins";
export default {
  name: "AllStaffs",
  data() {
    return {
      options: {},
      search: "",
      searchTerm: null
    };
  },
  components: {
    SnackBar: loadComponent(`component/SnackBar`)
  },

  computed: {
    ...mapGetters({
      allSchools: "schools/getAllSchools",
      pageLoading: "getIsPageLoading",
      isLoading: "getIsLoading",
      paginate: "schools/getPagination"
    }),
    form() {
      return {
        name: this.name,
        phone: this.phone,
        accountNumber: this.accountNumber
      };
    },
    staffList() {
      return this.allStaff.filter(searchTerm => {
        return (
          searchTerm.name.toLowerCase().indexOf(this.search.toLowerCase()) !==
          -1
        );
      });
    },

    headers() {
      return [
        {
          text: "",
          value: "avatar",
          filterable: false,
          sortable: false
        },
        {
          text: "Name",
          value: "name",
          filterable: true,
          sortable: false
        },
        {
          text: "Actions",
          value: "actions"
        }
      ];
    }
  },
  filters: {
    status(value) {
      return !value ? "Active" : "Inactive";
    },
    firstLetterFunction(value) {
      return value.charAt(0).toUpperCase();
    }
  },
  mixins: [SnackBarMixins],
  watch: {
    search: debounce(function(value) {
      if (value === "") {
        this.searchTerm = null;
        this.$store.dispatch(
          "members/list",
          `?page=1&size=30${
            this.activeAccountType ? "&accType=" + this.activeAccountType : ""
          }`
        );
        return;
      }
      this.searchTerm = value;
      const query = `?searchTerm=${value}&page=1&size=30${
        this.activeAccountType ? "&accType=" + this.activeAccountType : ""
      }`;
      this.$store.dispatch("members/searchList", query);
    }, 500),
    options: {
      handler(value) {
        const { itemsPerPage, page } = value;
        const query = `?page=${page}&size=${itemsPerPage}${
          this.activeAccountType ? "&accType=" + this.activeAccountType : ""
        }${this.searchTerm ? "&searchTerm=" + this.searchTerm : ""}`;
        if (this.searchTerm) {
          this.$store.dispatch("members/searchList", query);
        } else {
          this.$store.dispatch("members/list", query);
        }
      },
      deep: true
    },
    activeAccountType(value) {
      if (value) {
        const query = `?page=1&size=30&accType=${value}`;
        this.$store.dispatch("members/list", query);
        return;
      }
      const query = `?page=1&size=30`;
      this.$store.dispatch("members/list", query);
    }
  },
  methods: {},
  created() {
    const query = `?page=1&size=30`;
    this.$store.dispatch("schools/list", query);
  }
};
</script>

<style scoped>
.createNew {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: flex-end;
  align-items: stretch;
  alignt-content: stretch;
  margin-right: 25px;
}

.box {
  height: auto;
  width: auto;
  padding: 5%;
}
</style>
